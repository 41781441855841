<template>
  <div class="flex items-center w-full">
    <div
      class="fixed inset-0 overflow-hidden z-10 text-xs"
      v-if="selectOpen"
      @click="toggleSelect(false)"
    ></div>
    <div class="relative mx-3 text-xs">
      <div class="relative -mx-3">
        <input
          readonly
          @click="openSearchPanel"
          v-model="selectedName"
          class="w-full bge-input py-2 pl-4 pr-10 rounded cursor-pointer"
          type="text"
        />
        <button
          v-if="selectedName"
          @click="clearSelection"
          class="m-1 z-10 text-red-500 hover:bg-red-200 rounded-full absolute inset-y-0 right-0 flex items-center px-2 transition-colors duration-150 ease-in-out"
        >
          <svg
            class="stroke-current h-5 w-5"
            fill="none"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            viewBox="0 0 24 24"
          >
            <path d="M18 6L6 18M6 6l12 12" />
          </svg>
        </button>
      </div>
      <div
        v-if="selectOpen"
        class="z-10 absolute w-full max-h-5vh bg-white shadow-lg rounded border border-gray-300 top-0 mt-5 p-2"
      >
        <div class="relative flex flex-col">
          <input
            ref="searchInput"
            type="text"
            v-model="searchTerm"
            @keydown="executeSearch"
            class="bge-input bge-input-spacing rounded"
            placeholder="Name(min 3 letters)"
          />
          <div class="flex flex-col overflow-y-scroll bg-white mt-2">
            <template v-for="result in searchResults">
              <button
                type="button"
                :key="result.id"
                @click="selectItem(result)"
                class="hover:bg-orange-200 flex items-center py-2 transition duration-100 focus:bg-orange-200 text-left px-2"
              >
                <span class="ml-1">
                  {{ result.name }}
                </span>
              </button>
            </template>
            <p class="mx-auto py-4 px-8" v-if="searchResults.length === 0">
              No matches found
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  props: {
    fetchDataFunction: {
      type: Function,
      required: true,
    },
    selectedItem: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      selectOpen: false,
      searchTerm: "",
      selectedName: "",
      searchResults: [],
    };
  },
  watch: {
    selectedItem: {
      immediate: true,
      handler(newItem) {
        this.updateSelectedItem(newItem);
      },
    },
  },
  methods: {
    openSearchPanel() {
      this.resetSearch();
      this.toggleSelect(true);
      this.focusOnSearchInput();
    },

    focusOnSearchInput() {
      this.$nextTick(() => {
        this.$refs.searchInput.focus();
      });
    },

    resetSearch() {
      this.searchTerm = "";
      this.searchResults = [];
    },

    toggleSelect(isOpen) {
      this.selectOpen = isOpen;
    },

    executeSearch: _.debounce(async function () {
      console.log(typeof this.fetchDataFunction);
      this.searchResults = [];
      if (this.isSearchTermValid(this.searchTerm)) {
        let results = await this.fetchDataFunction(this.searchTerm);
        this.populateResults(results);
      }
    }, 500),

    isSearchTermValid(term) {
      return term && term.length >= 3;
    },

    populateResults(results) {
      this.searchResults = results || [];
    },

    selectItem(item) {
      this.updateSelectedItem(item);
      this.toggleSelect(false);
      this.$emit("itemSelected", this.selectedItem);
    },

    updateSelectedItem(item) {
      this.selectedItem = item;
      this.selectedName = item.name || "";
    },

    clearSelection() {
      this.selectedItem = {};
      this.selectedName = "";
      this.$emit("itemCleared");
    },
  },
};
</script>
