<template>
  <div>
    <div v-if="searchState === 'Idle'">
      <vue-good-table
        class="mt-5"
        styleClass="vgt-table striped"
        :rows="formattedLeaves"
        :columns="columns"
        :pagination-options="paginationOptions"
      >
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field == 'actions'">
            <is-authorized :permissions="['manage:leaveAll']">
              <button
                type="button"
                @click="deleteLeave(props.row)"
                class="flex items-center ml-2 float-right bg-red-200 border hover:bg-red-300 border-red-400 hover:border-red-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
              >
                <svg
                  class="stroke-current h-5 w-5 mr-2"
                  fill="none"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M3 6h18M19 6v14a2 2 0 01-2 2H7a2 2 0 01-2-2V6m3 0V4a2 2 0 012-2h4a2 2 0 012 2v2M10 11v6M14 11v6"
                  />
                </svg>
                <span></span>
              </button>
            </is-authorized>
            <is-authorized :permissions="['manage:leaveAll']">
              <button
                type="button"
                @click="prepareLeaveForEdit(props.row)"
                class="float-right flex items-center bg-blue-200 border hover:bg-blue-300 border-blue-400 hover:border-blue-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
              >
                <svg
                  class="stroke-current h-5 w-5 mr-2"
                  fill="none"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M17 3a2.828 2.828 0 114 4L7.5 20.5 2 22l1.5-5.5L17 3z"
                  />
                </svg>
                <span></span>
              </button>
            </is-authorized>
          </span>

          <span v-else>{{ props.formattedRow[props.column.field] }}</span>
        </template>
      </vue-good-table>
    </div>
    <div v-if="searchState === 'Searching'">
      <Spinner :color="'text-black'" :size="10" />
    </div>
    <div v-if="searchState === 'Error'">
      <div class="mt-5 p-6 rounded bg-red-300">
        Something went wrong, please try again. If the problem persists, contact
        support.
      </div>
    </div>
    <portal to="overlay-outlet">
      <Panel
        :showing="showingAddEditLeavePanel"
        @close="showingAddEditLeavePanel = false"
        :title="leaveToEdit.isEdit ? 'Edit Leave' : 'Create Leave'"
      >
        <addEditLeave
          :staff="leaveToEdit"
          :managingOwnLeave="false"
          @complete="leaveCreated"
        />
      </Panel>
    </portal>
  </div>
</template>

<script>
import Spinner from "@/components/Spinner.vue";
import Panel from "@/components/Panel.vue";
import { mapGetters, mapActions } from "vuex";
const IsAuthorized = () => import("@/components/Auth/IsAuthorized.vue");
const addEditLeave = () =>
  import("@/components/Dashboard/Leaves/CreateLeave.vue");
export default {
  components: {
    Spinner,
    IsAuthorized,
    Panel,
    addEditLeave,
  },
  props: {
    type: {
      type: String,
      validator: function (value) {
        return ["upcoming", "history"].includes(value);
      },
      required: true,
    },
  },
  data() {
    return {
      showingAddEditLeavePanel: false,
      leaveToEdit: {},
      columns: [
        {
          label: "Name",
          field: this.fullName,
          sortable: false,
          width: "20%",
        },
        {
          label: "Leave Type",
          field: "leaves.leave_type",
          sortable: true,
          width: "20%",
        },
        {
          label: "Start Date",
          field: "leaves.formattedStartDate",
          sortable: true,
          width: "10%",
        },

        {
          label: "End Date",
          field: "leaves.formattedEndDate",
          sortable: true,
          width: "10%",
        },
        {
          label: "No Of Days",
          field: "leaves.days",
          sortable: false,
          width: "10%",
        },
        {
          label: "Status",
          field: "leaves.status",
          formatFn: this.uppercase,
          sortable: true,
          width: "10%",
        },
        {
          label: "Request Date",
          field: "leaves.formattedRequestDate",
          sortable: false,
          width: "10%",
        },
        {
          field: "actions",
          type: "custom",
          sortable: false,
          width: "10%",
        },
      ],
      paginationOptions: {
        enabled: true,
        perPage: 10,
        perPageDropdown: [1, 2, 3, 10, 20, 50, 100],
        dropdownAllowAll: false,
      },
    };
  },
  computed: {
    ...mapGetters([
      "leaves",
      "totalRecords",
      "searchState",
      "department",
      "status",
      "page",
      "perPage",
      "selectedStaff",
      "currentTab",
      "formattedLeaves",
    ]),
  },

  methods: {
    ...mapActions("leaves", ["fetchLeaves"]),

    fullName(rowObj) {
      return rowObj.first_name + " " + rowObj.last_name;
    },
    uppercase: function (v) {
      return v.toUpperCase();
    },
    async prepareLeaveForEdit(staffLeave) {
      this.leaveToEdit = this.cloneLeave(staffLeave);
      this.leaveToEdit.isEdit = true;
      this.showEditLeavePanel();
    },
    showEditLeavePanel() {
      this.showingAddEditLeavePanel = true;
    },
    cloneLeave(staffLeave) {
      return {
        ...staffLeave,
        leaves: { ...staffLeave.leaves },
      };
    },
    leaveCreated: async function () {
      await this.loadLeaves();
      this.showingAddEditLeavePanel = false;
    },
  },

  created() {
    const params = {
      page: this.page,
      limit: this.perPage,
      name: this.currentTab,
    };

    this.fetchLeaves(params);
  },
};
</script>
