<template>
  <div class="w-full max-h-screen bg-background flex flex-col relative">
    <div
      v-show="isBusy"
      class="absolute inset-0 bg-faint-white flex items-center justify-center z-20"
    >
      <spinner :color="'text-black'" :size="10" />
    </div>
    <page-header title="Holiday Management" backTo="internal">
      <button
        @click="createLeave"
        class="flex items-center border bg-green-200 hover:bg-green-300 border-green-400 hover:border-green-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
      >
        <svg
          class="stroke-current h-5 w-5 mr-2"
          fill="none"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          viewBox="0 0 24 24"
        >
          <path d="M12 5v14M5 12h14" />
        </svg>
        <span>Create Leave</span>
      </button>
      <is-authorized :permissions="['manage:leaveAll']">
        <button
          @click="createSetting"
          type="button"
          class="ml-4 flex items-center bg-red-200 border hover:bg-red-300 border-red-400 hover:border-red-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
        >
          <svg
            class="stroke-current h-5 w-5 mr-2"
            fill="none"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            viewBox="0 0 24 24"
          >
            <path d="M0 0h24v24H0z" stroke="none" />
            <path
              d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 0 0 2.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 0 0 1.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 0 0-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 0 0-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 0 0-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 0 0-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 0 0 1.066-2.573c-.94-1.543.826-3.31 2.37-2.37 1 .608 2.296.07 2.572-1.065z"
            />
            <circle cx="12" cy="12" r="3" />
          </svg>
          <span>Settings</span>
        </button>
      </is-authorized>
    </page-header>

    <div class="p-6 bg-background flex-1 overflow-y-scroll">
      <div class="w-full bg-white p-5 rounded shadow-md relative">
        <div class="w-full flex flex-col">
          <div class="flex -mx-2 mr-2 w-full justify-start p-1">
            <button
              @click="selectTab('overview')"
              class="px-4 py-2 rounded mx-2 transition-colors duration-300 flex items-center font-semibold"
              :class="{
                'bg-orange-300 text-orange-900': currentTab == 'overview',
                'hover:bg-gray-200': currentTab != 'overview',
              }"
            >
              <svg
                class="stroke-current h-5 w-5 mr-2"
                fill="none"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                viewBox="0 0 24 24"
              >
                <path d="M0 0h24v24H0z" stroke="none" />
                <path d="M3 5v14l8-7zM14 5v14l8-7z" />
              </svg>
              <span>Overview</span>
            </button>
            <button
              @click="selectTab('upcoming')"
              class="px-4 py-2 rounded mx-2 transition-colors duration-300 flex items-center font-semibold"
              :class="{
                'bg-orange-300 text-orange-900': currentTab == 'upcoming',
                'hover:bg-gray-200': currentTab != 'upcoming',
              }"
            >
              <svg
                class="stroke-current h-5 w-5 mr-2"
                fill="none"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                viewBox="0 0 24 24"
              >
                <path d="M0 0h24v24H0z" stroke="none" />
                <path d="M3 5v14l8-7zM14 5v14l8-7z" />
              </svg>
              <span>Upcoming</span>
            </button>
            <button
              @click="selectTab('history')"
              class="px-4 py-2 rounded mx-2 transition-colors duration-300 flex items-center font-semibold"
              :class="{
                'bg-orange-300 text-orange-900': currentTab == 'history',
                'hover:bg-gray-200': currentTab != 'history',
              }"
            >
              <svg
                class="stroke-current h-5 w-5 mr-2"
                fill="none"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                viewBox="0 0 24 24"
              >
                <path d="M0 0h24v24H0z" stroke="none" />
                <path d="M12 8v4l2 2" />
                <path d="M3.05 11a9 9 0 1 1 .5 4m-.5 5v-5h5" />
              </svg>
              <span>History</span>
            </button>

            <button
              @click="selectTab('calendar')"
              class="px-4 py-2 rounded mx-2 transition-colors duration-300 flex items-center font-semibold"
              :class="{
                'bg-orange-300 text-orange-900': currentTab == 'calendar',
                'hover:bg-gray-200': currentTab != 'calendar',
              }"
            >
              <svg
                class="stroke-current h-5 w-5 mr-2"
                fill="none"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                viewBox="0 0 24 24"
              >
                <path d="M0 0h24v24H0z" stroke="none" />
                <rect x="4" y="5" width="16" height="16" rx="2" />
                <path d="M16 3v4M8 3v4M4 11h16M11 15h1M12 15v3" />
              </svg>
              <span>Calendar View</span>
            </button>
          </div>

          <div class="flex items-center -mx-2 mr-2 w-full justify-end p-1">
            <is-authorized
              v-if="currentTab != 'overview'"
              :permissions="['manage:leaveAll']"
            >
              <div class="flex flex-grow pr-2 items-center flex-nowrap">
                <label
                  class="uppercase font-semibold text-xs mr-2 flex-shrink-0"
                  >Search Staff</label
                >
                <div class="flex-grow">
                  <SearchComponent
                    :fetchDataFunction="fetchStaffData"
                    :selectedItem="selectedStaff"
                    @itemSelected="handleStaffSelected"
                    @itemCleared="handleStaffCleared"
                  ></SearchComponent>
                </div>
              </div>
            </is-authorized>

            <is-authorized
              v-if="currentTab != 'overview'"
              :permissions="['manage:leaveAll']"
            >
              <div class="flex pr-2 items-center">
                <label class="uppercase font-semibold text-xs mr-2"
                  >Status</label
                >
                <div class="inline-block relative w-full">
                  <select
                    v-model="selectedStatus"
                    @change="statusChanged"
                    class="bge-input bge-select rounded text-xs"
                  >
                    <option value="All">All</option>
                    <option value="pending">Pending</option>
                    <option value="approved">Approved</option>
                    <option value="rejected">Rejected</option>
                  </select>
                  <div
                    class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
                  >
                    <svg class="fill-current h-4 w-4" viewBox="0 0 20 20">
                      <path
                        d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                      ></path>
                    </svg>
                  </div>
                </div>
              </div>
            </is-authorized>

            <is-authorized :permissions="['manage:leaveAll']">
              <div class="flex items-center">
                <label class="uppercase font-semibold text-xs mr-2"
                  >Department</label
                >
                <div class="inline-block relative w-full">
                  <select
                    v-model="selectedDepartment"
                    @change="departmentChanged"
                    class="bge-input bge-select rounded text-xs"
                  >
                    <template v-for="(department, i) in departments">
                      <option :key="i" :value="department">
                        {{ department }}
                      </option>
                    </template>
                  </select>
                  <div
                    class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
                  >
                    <svg class="fill-current h-4 w-4" viewBox="0 0 20 20">
                      <path
                        d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                      ></path>
                    </svg>
                  </div>
                </div>
              </div>
            </is-authorized>
          </div>
        </div>

        <div v-if="viewState == 'Idle'" class="space-y-5 mt-5">
          <!-- Overview Tab -->
          <div v-if="currentTab == 'overview'" class="space-y-8">
            <div
              class="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-12"
            >
              <template v-for="(leaveData, i) in leaveOverview">
                <div
                  :key="i"
                  class="group rounded-lg border border-gray-300 shadow-sm transition-shadow transition-transform transform hover:shadow-lg hover:-translate-y-1 cursor-pointer p-6 hover:bg-orange-300"
                  :class="{
                    'bg-orange-100 text-orange-800 border-orange-300':
                      selectedStaff.id == leaveData.staff_id,
                  }"
                  @click="navigateToStaffLeaveDetails(leaveData)"
                >
                  <h3
                    class="text-xl font-semibold text-gray-700 dark:text-gray-100 mb-5 uppercase group-hover:text-orange-900"
                  >
                    {{ leaveData.staff_first_name }}
                    {{ leaveData.staff_last_name }}
                  </h3>

                  <div class="text-gray-700 space-y-5">
                    <div class="flex justify-between">
                      <p
                        class="text-base font-medium dark:text-gray-600 uppercase group-hover:text-orange-800"
                      >
                        Days Taken
                      </p>
                      <p
                        class="text-lg text-right text-gray-700 dark:text-gray-100 group-hover:text-orange-900"
                      >
                        {{ leaveData.total_leaves_taken }}
                      </p>
                    </div>
                    <div class="flex justify-between">
                      <p
                        class="text-base font-medium dark:text-gray-600 uppercase group-hover:text-orange-800"
                      >
                        Days Remaining
                      </p>
                      <p
                        class="text-lg text-right text-gray-700 dark:text-gray-100 group-hover:text-orange-900"
                      >
                        {{ leaveData.total_leaves_available }}
                      </p>
                    </div>
                    <div class="flex justify-between">
                      <p
                        class="text-base font-medium dark:text-gray-600 uppercase group-hover:text-orange-800"
                      >
                        Days Booked Next Period
                      </p>
                      <p
                        class="text-lg text-right text-gray-700 dark:text-gray-100 group-hover:text-orange-900"
                      >
                        {{ leaveData.total_leaves_upcoming }}
                      </p>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>

          <div v-if="currentTab == 'overview1'" class="space-y-8">
            <LeaveList type="upcoming" />
          </div>

          <!-- Upcoming Section -->
          <div v-if="currentTab == 'upcoming'">
            <vue-good-table
              class="mt-5"
              styleClass="vgt-table striped"
              mode="remote"
              :rows="leaves"
              :columns="columns"
              :totalRows="totalRecords"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange"
              :pagination-options="{
                enabled: true,
                perPage: pagingParams.limit,
                perPageDropdown: [1, 2, 3, 10, 20, 50, 100],
                dropdownAllowAll: false,
              }"
            >
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == 'actions'">
                  <div class="relative flex justify-end space-x-4">
                    <button
                      type="button"
                      @click="prepareLeaveForEdit(props.row)"
                      class="float-right flex items-center bg-blue-200 border hover:bg-blue-300 border-blue-400 hover:border-blue-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
                    >
                      <svg
                        class="stroke-current h-5 w-5 mr-2"
                        fill="none"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        viewBox="0 0 24 24"
                      >
                        <path
                          d="M17 3a2.828 2.828 0 114 4L7.5 20.5 2 22l1.5-5.5L17 3z"
                        />
                      </svg>
                      <span></span>
                    </button>

                    <button
                      type="button"
                      @click="deleteLeave(props.row)"
                      class="flex items-center ml-2 float-right bg-red-200 border hover:bg-red-300 border-red-400 hover:border-red-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
                    >
                      <svg
                        class="stroke-current h-5 w-5 mr-2"
                        fill="none"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        viewBox="0 0 24 24"
                      >
                        <path
                          d="M3 6h18M19 6v14a2 2 0 01-2 2H7a2 2 0 01-2-2V6m3 0V4a2 2 0 012-2h4a2 2 0 012 2v2M10 11v6M14 11v6"
                        />
                      </svg>
                      <span></span>
                    </button>
                  </div>
                </span>

                <span v-else>{{ props.formattedRow[props.column.field] }}</span>
              </template>
            </vue-good-table>
          </div>

          <!-- History Section -->

          <div v-if="currentTab == 'history'">
            <div v-show="searchState == 'Idle'">
              <vue-good-table
                class="mt-5"
                styleClass="vgt-table striped"
                mode="remote"
                :rows="leaves"
                :columns="columns"
                :totalRows="totalRecords"
                @on-page-change="onPageChange"
                @on-sort-change="onSortChange"
                @on-column-filter="onColumnFilter"
                @on-per-page-change="onPerPageChange"
                :pagination-options="{
                  enabled: true,
                  perPage: pagingParams.limit,
                  perPageDropdown: [1, 2, 3, 10, 20, 50, 100],
                  dropdownAllowAll: false,
                }"
              >
                <template slot="table-row" slot-scope="props">
                  <span v-if="props.column.field == 'actions'">
                    <is-authorized :permissions="['manage:leaveAll']">
                      <button
                        type="button"
                        @click="deleteLeave(props.row)"
                        class="flex items-center ml-2 float-right bg-red-200 border hover:bg-red-300 border-red-400 hover:border-red-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
                      >
                        <svg
                          class="stroke-current h-5 w-5 mr-2"
                          fill="none"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          viewBox="0 0 24 24"
                        >
                          <path
                            d="M3 6h18M19 6v14a2 2 0 01-2 2H7a2 2 0 01-2-2V6m3 0V4a2 2 0 012-2h4a2 2 0 012 2v2M10 11v6M14 11v6"
                          />
                        </svg>
                        <span></span>
                      </button>
                    </is-authorized>
                    <is-authorized :permissions="['manage:leaveAll']">
                      <button
                        type="button"
                        @click="prepareLeaveForEdit(props.row)"
                        class="float-right flex items-center bg-blue-200 border hover:bg-blue-300 border-blue-400 hover:border-blue-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
                      >
                        <svg
                          class="stroke-current h-5 w-5 mr-2"
                          fill="none"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          viewBox="0 0 24 24"
                        >
                          <path
                            d="M17 3a2.828 2.828 0 114 4L7.5 20.5 2 22l1.5-5.5L17 3z"
                          />
                        </svg>
                        <span></span>
                      </button>
                    </is-authorized>
                  </span>

                  <span v-else>{{
                    props.formattedRow[props.column.field]
                  }}</span>
                </template>
              </vue-good-table>
              <!-- <div class="w-full bg-white p-5 rounded shadow-md relative">
                <div class="demo-app" style="white">
                  <div class="demo-app-main">
                    <FullCalendar
                      class="demo-app-calendar"
                      :options="calendarOptions"
                    >
                      <template v-slot:eventContent="arg">
                        <b>{{ arg.timeText }}</b>
                        <i>{{ arg.event.title }}</i>
                      </template>
                    </FullCalendar>
                  </div>
                </div>
              </div> -->
            </div>
            <div
              v-if="searchState == 'Searching'"
              class="flex items-center justify-center py-20"
            >
              <Spinner :color="'text-black'" :size="10" />
            </div>
            <div v-if="searchState == 'Error'">
              <div class="mt-5 p-6 rounded bg-red-300">
                Something went wrong performing your search, please try again.
                If the problem persists, please contact your support team.
              </div>
            </div>
          </div>

          <!-- Calendar section  -->
          <div v-if="currentTab == 'calendar'">
            <div class="w-full bg-white p-5 rounded relative">
              <div class="demo-app" style="white">
                <div class="demo-app-main">
                  <FullCalendar
                    class="demo-app-calendar"
                    :options="calendarOptions"
                    ref="fullCalendar"
                  >
                    <template v-slot:eventContent="arg">
                      <!-- <b>{{ arg.timeText }}</b> -->
                      <i>{{ arg.event.title }}</i>
                    </template>
                  </FullCalendar>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="flex justify-end mt-5"></div>
      </div>
    </div>
    <portal to="overlay-outlet">
      <Panel
        :showing="showingAddEditLeavePanel"
        @close="showingAddEditLeavePanel = false"
        :title="leaveToEdit.isEdit ? 'Edit Leave' : 'Create Leave'"
      >
        <addEditLeave
          :staff="leaveToEdit"
          :managingOwnLeave="manageOwnLeave"
          @complete="leaveCreated"
        />
      </Panel>
    </portal>
    <portal to="overlay-outlet">
      <Panel
        :showing="showingAddEditSettingPanel"
        @close="showingAddEditSettingPanel = false"
        title="Leave Setting"
      >
        <addEditSetting :staff="leaveToEdit" @complete="settingCreated" />
      </Panel>
    </portal>
    <portal to="overlay-outlet">
      <confirm-alert
        :showingConfirm="confirmAlert.showing"
        :message="confirmAlert.message"
        @complete="confirmAlert.resultFunction"
      />
    </portal>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader.vue";
import Spinner from "@/components/Spinner.vue";
import Panel from "@/components/Panel.vue";
import SearchComponent from "@/components/SearchComponent.vue";
import LeaveList from "@/components/Dashboard/Leaves/LeaveList.vue";

const Notification = () => import("@/components/Notification.vue");
const ConfirmAlert = () => import("@/components/ConfirmAlert.vue");
const addEditLeave = () =>
  import("@/components/Dashboard/Leaves/CreateLeave.vue");

const addEditSetting = () =>
  import("@/components/Dashboard/Leaves/CreateSetting.vue");
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from "@fullcalendar/list";
import tippy from "tippy.js";
import "tippy.js/dist/tippy.css"; // optional for styling
import "tippy.js/themes/material.css";
// import interactionPlugin from '@fullcalendar/interaction'
import { DEPARTMENTS } from "@/utils/constants";

const IsAuthorized = () => import("@/components/Auth/IsAuthorized.vue");

export default {
  components: {
    SearchComponent,
    Notification,
    ConfirmAlert,
    PageHeader,
    Spinner,
    Panel,
    FullCalendar,
    addEditLeave,
    addEditSetting,
    isBusy: false,
    IsAuthorized,
    LeaveList,
  },
  data: function () {
    return {
      viewState: "Idle",
      searchState: "Idle",
      currentTab: "overview",
      searchTerm: "",
      searchTermWas: "",
      searchResults: [],
      totalSearchResults: 0,
      manageOwnLeaveStaffId: "",
      manageOwnLeave: false,
      manageOwnLeaveStaff: {},

      staffName: "",
      staffSearchTerm: "",
      staffResults: [],
      staffSelectOpen: false,
      selectedStaff: {},
      error: null,
      totalRecords: 0,
      leaveToEdit: {},
      pagingParams: {
        page: 1,
        limit: 50,
      },
      searchPagingParams: {
        page: 1,
        limit: 50,
      },
      confirmAlert: {
        resultFunction: "",
        showing: false,
        message: "",
        title: "",
        data: "",
      },
      leaves: [],
      leaveSource: [],
      departments: [],
      leaveOverview: [],
      selectedDepartment: "All",
      selectedStatus: "All",
      columns: [
        {
          label: "Name",
          field: this.fullName,
          sortable: false,
          width: "20%",
        },
        {
          label: "Leave Type",
          field: "leaves.leave_type",
          sortable: true,
          width: "20%",
        },
        {
          label: "Start Date",
          field: "leaves.start_date",
          sortable: true,
          formatFn: this.dateFormatter,
          width: "10%",
        },

        {
          label: "End Date",
          field: "leaves.end_date",
          sortable: true,
          formatFn: this.dateFormatter,
          width: "10%",
        },
        {
          label: "No Of Days",
          field: "leaves.days",
          sortable: false,
          width: "10%",
        },
        {
          label: "Status",
          field: "leaves.status",
          formatFn: this.uppercase,
          sortable: true,
          width: "10%",
        },
        {
          label: "Request Date",
          field: "leaves.request_date",
          sortable: false,
          formatFn: this.dateFormatter,
          width: "10%",
        },
        {
          field: "actions",
          type: "custom",
          sortable: false,
          width: "10%",
        },
      ],

      showingAddEditLeavePanel: false,
      showingAddEditSettingPanel: false,

      calendarOptions: {
        plugins: [
          dayGridPlugin,
          listPlugin,
          // interactionPlugin // needed for dateClick
        ],
        headerToolbar: {
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth listWeek",
        },
        initialView: "dayGridMonth",
        buttonText: {
          today: "This Month",
          month: "Month",
          week: "week",
          day: "day",
          list: "List",
        },
        eventDidMount: function (info) {
          if (info.event.extendedProps.description) {
            tippy(info.el, {
              content: info.event.extendedProps.description,
              theme: "material",
            });
          }
        },
        events: this.events,
        height: 750,
        contentHeight: 650,
        dayMaxEvents: true,
        weekends: true,
        fixedWeekCount: false,
        eventClassNames: function (arg) {
          if (arg.event.display == "background") {
            return [""];
          } else {
            return ["fc-events"];
          }
        },
      },
      currentEvents: [],
      events: [],
      config: {
        defaultView: "month",
        eventRender: function (event, element) {
          console.log(event);
        },
      },
    };
  },
  async mounted() {
    try {
      this.setLoadingState();
      this.prepareDepartmentsList();
      await this.loadLeaves();
      this.setSearchTermFromStore();
      this.setIdleState();
    } catch (error) {
      this.handleError(error);
    }
  },
  methods: {
    async fetchStaffData(term) {
      try {
        let results = await this.HolidayService.searchStaff(term, {
          select: "staff_id,first_name,last_name",
        });

        if (results && results.data) {
          return results.data.map((staff) => ({
            id: staff.staff_id,
            name: `${staff.first_name} ${staff.last_name}`,
          }));
        } else {
          return [];
        }
      } catch (error) {
        console.error("Error fetching staff data:", error);
        return [];
      }
    },
    handleStaffSelected(selectedItem) {
      this.selectedStaff = selectedItem;
      this.loadLeaves();
    },
    handleStaffCleared() {
      this.selectedStaff = {};
      this.loadLeaves();
      this.$forceUpdate();
    },
    setLoadingState() {
      this.viewState = "Loading";
    },
    setIdleState() {
      this.viewState = "Idle";
    },
    prepareDepartmentsList() {
      let departmentsCopy = [...DEPARTMENTS];
      if (!departmentsCopy.includes("All")) {
        departmentsCopy.unshift("All");
      }
      this.departments = departmentsCopy;
    },
    setSearchTermFromStore() {
      this.searchTerm = this.$store.state.leaveSearchTerm;
    },
    handleError(error) {
      console.error(error);
      this.viewState = "Error";
      this.error = error;
    },
    navigateToStaffLeaveDetails: function (obj) {
      const selectedStaffObj = {
        id: obj.staff_id,
        name: `${obj.staff_first_name} ${obj.staff_last_name}`,
      };
      this.selectedStaff = selectedStaffObj;
      this.selectTab("upcoming");
    },
    uppercase: function (v) {
      return v.toUpperCase();
    },
    async statusChanged() {
      if (!this.selectedStatus) {
        return;
      }
      let filteredLeaves = await this.filterLeavesByDepartmentAndStatus(
        this.leaveSource,
      );

      await this.updateLeavesInView(filteredLeaves, filteredLeaves.count);
    },
    async departmentChanged() {
      const department = this.selectedDepartment;
      await this.$store.dispatch("updateDepartment", department);

      const filterOverview = {};

      if (department && department.toLowerCase() !== "all") {
        filterOverview.department = department.toLowerCase();
      }

      await this.$store.dispatch("fetchLeaves");

      const response = await this.HolidayService.getLeaveOverview(
        filterOverview,
        this.$store.state.userId,
      );
      this.leaveOverview = response.data;

      const filteredLeaves = await this.filterLeavesByDepartmentAndStatus(
        this.leaveSource,
      );
      await this.updateLeavesInView(filteredLeaves, filteredLeaves.count);
    },
    async filterLeavesByDepartmentAndStatus(leaves) {
      const selectedDepartment = this.selectedDepartment.toLowerCase();
      const selectedStatus = this.selectedStatus.toLowerCase();

      const isDepartmentMatching = (itemDepartments) => {
        if (selectedDepartment === "all" || selectedDepartment === "")
          return true;
        for (let dept of itemDepartments) {
          if (dept.toLowerCase().includes(selectedDepartment)) {
            return true;
          }
        }
        return false;
      };

      const isStatusMatching = (itemStatus) => {
        return (
          selectedStatus === "all" ||
          selectedStatus === "" ||
          (itemStatus && itemStatus.includes(selectedStatus))
        );
      };

      return leaves.filter((item) => {
        const itemStatus = item.leaves.status
          ? item.leaves.status.toLowerCase()
          : "";

        return (
          isDepartmentMatching(item.department) && isStatusMatching(itemStatus)
        );
      });
    },

    dateFormatter(date) {
      return this.$moment(date).format("DD/MM/YYYY");
    },
    tblClassLeftAlign(row) {
      return "vgt-left-align";
    },
    tblClassCenterAlign(row) {
      return "vgt-center-align";
    },
    fullName(rowObj) {
      return rowObj.first_name + " " + rowObj.last_name;
    },
    selectTab: async function (tabName) {
      this.$nextTick(async () => {
        this.currentTab = tabName;
        this.selectedDepartment = "All";
        await this.loadLeaves();
      });
      this.$store.dispatch("setLeaveSearchTerm", "");
    },

    canManageAllLeaves: async function () {
      return this.$store.state.userPermissions.includes("manage:leaveAll")
        ? true
        : false;
    },

    async loadLeaves() {
      this.isBusy = true;

      const canManageAll = await this.canManageAllLeaves();
      const departmentFilter = this.getDepartmentFilter();
      this.leaveOverview = (
        await this.HolidayService.getLeaveOverview(
          departmentFilter,
          this.$store.state.userId,
        )
      ).data;

      let leaveResults = canManageAll
        ? await this.fetchAllLeaves()
        : await this.fetchUserSpecificLeaves();

      this.isBusy = false;
      this.formatDatesInResults(leaveResults.data);
      this.leaveSource = leaveResults.data;

      this.resetFilters();
      await this.updateLeavesInView(leaveResults.data, leaveResults.count);
    },

    async fetchAllLeaves() {
      return await this.HolidayService.searchAllLeaves("onlyLeaves", {
        ...this.searchPagingParams,
        name: this.currentTab,
        staff_id: this.selectedStaff.id,
      });
    },

    getDepartmentFilter() {
      let department = this.selectedDepartment;
      let filter = {};
      if (department.toLowerCase() !== "all" && department) {
        filter.department = department.toLowerCase();
      }
      return filter;
    },

    async fetchUserSpecificLeaves() {
      const userEmail = this.$store.state.userEmail;
      let results = await this.HolidayService.searchStaff(userEmail, {
        select: "staff_id,first_name,last_name,leave_balance",
        staff_email: userEmail,
      });

      if (results.data && results.data.length > 0) {
        let staff = results.data[0];
        this.manageOwnLeaveStaff = staff;
        this.manageOwnLeaveStaffId = staff.staff_id;
      }

      return await this.HolidayService.searchStaffLeaves("onlyLeaves", {
        ...this.searchPagingParams,
        name: this.currentTab,
        staff_email: userEmail,
      });
    },

    formatDatesInResults(data) {
      data.forEach((d) => {
        d.leaves.start_date = this.$moment
          .unix(d.leaves.start_date, "DD/MM/YYYY")
          .toDate();
        d.leaves.end_date = this.$moment
          .unix(d.leaves.end_date, "DD/MM/YYYY")
          .toDate();
        d.leaves.request_date = this.$moment
          .unix(d.leaves.request_date, "DD/MM/YYYY")
          .toDate();
      });
    },

    resetFilters() {
      this.selectedDepartment = "All";
      this.selectedStatus = "All";
    },

    async updateLeavesInView(leaves, totalLeavesCount) {
      this.leaves = leaves;
      this.totalRecords = totalLeavesCount;

      if (this.currentTab === "calendar") {
        const departmentParam =
          (this.selectedDepartment &&
            this.selectedDepartment.toLowerCase() === "all") ||
          !this.selectedDepartment
            ? null
            : this.selectedDepartment;

        const statusParam =
          (this.selectedStatus &&
            this.selectedStatus.toLowerCase() === "all") ||
          !this.selectedStatus
            ? null
            : this.selectedStatus;

        const staffIdParam = this.selectedStaff.id;

        const response = await this.HolidayService.getLeaveSummary(
          {
            department: departmentParam,
            status: statusParam,
            searchStaffId: staffIdParam,
          },
          this.$store.state.userId,
        );
        const leavesForCalendarView = this.transformSummariesForCalendar(
          response.data,
        );

        this.loadLeavesToCalendar(leavesForCalendarView);
      }

      this.$forceUpdate();
    },

    transformLeavesForCalendar(leaves) {
      return leaves.map((leave) => ({
        resourceId: leave.staff_id,
        title: `${leave.first_name} - ${leave.leaves.leave_type}`,
        start: leave.leaves.start_date,
        end: leave.leaves.end_date,
        color: "#E3EDFF",
        textColor: "#383E42",
        description: leave.leaves.purpose,
        allDay: true,
      }));
    },

    transformSummariesForCalendar(summaries) {
      return summaries.map((summary) => {
        // Parse the start and end dates
        const startDate = this.$moment(
          summary.start_date,
          "DD-MM-YYYY",
        ).toDate();
        let endDate = this.$moment(summary.end_date, "DD-MM-YYYY").toDate();

        // Adjust the end date to be one day later
        endDate.setDate(endDate.getDate() + 1);

        return {
          resourceId: summary.staff_id,
          title: `${summary.first_name} ${summary.last_name} - ${summary.leave_type}`,
          start: startDate,
          end: endDate,
          color: "#E3EDFF",
          textColor: "#383E42",
          description: `${summary.leave_type}-${summary.status}`,
          allDay: true,
        };
      });
    },

    loadLeavesToCalendar(leaves) {
      const calendarApi = this.$refs.fullCalendar.getApi();
      const existingSources = calendarApi.getEventSources();

      existingSources.forEach((source) => source.remove());

      if (leaves && leaves.length > 0) {
        leaves.push({
          daysOfWeek: [0, 6],
          overlap: false,
          display: "background",
          backgroundColor: "#fff7a3",
        });
      }

      calendarApi.addEventSource(leaves);
    },

    createLeave: async function () {
      this.leaveToEdit = {};
      let canManageAll = await this.canManageAllLeaves();
      if (!canManageAll) {
        this.leaveToEdit = this.manageOwnLeaveStaff;
        this.manageOwnLeave = true;
      }
      this.showingAddEditLeavePanel = true;
    },
    leaveCreated: async function () {
      await this.loadLeaves();
      this.showingAddEditLeavePanel = false;
    },
    createSetting: function () {
      this.leaveToEdit = {};
      this.showingAddEditSettingPanel = true;
    },
    settingCreated: async function () {
      await this.loadLeaves();
      this.showingAddEditLeavePanel = false;
    },
    async onPageChange(params) {
      this.updatePagingParams({ page: params.currentPage });
      await this.loadLeaves();
    },
    async onPerPageChange(params) {
      this.updatePagingParams({ limit: params.currentPerPage });
      await this.loadLeaves();
    },

    async onSortChange(params) {
      this.updatePagingParams({
        order: params[0].type,
        orderBy: params[0].field,
      });

      await this.loadLeaves();
    },
    markRowAsSelected: function (row) {
      return this.$store.state.lastSelectedlEAVE == row.leave_id
        ? "bg-green-300"
        : "";
    },

    updatePagingParams(newProps) {
      // if (this.currentTab == "history") {
      this.searchPagingParams = Object.assign(
        {},
        this.searchPagingParams,
        newProps,
      );
    },
    onColumnFilter(params) {
      console.log("onColumnFilter", params);
    },

    async prepareLeaveForEdit(staffLeave) {
      this.leaveToEdit = this.cloneLeave(staffLeave);
      this.leaveToEdit.isEdit = true;

      let canEditAll = await this.canManageAllLeaves();
      this.updateEditingPermissions(canEditAll);

      this.showEditLeavePanel();
    },

    cloneLeave(staffLeave) {
      return {
        ...staffLeave,
        leaves: { ...staffLeave.leaves },
      };
    },

    updateEditingPermissions(canEditAll) {
      if (!canEditAll) {
        this.leaveToEdit.staff_id = this.manageOwnLeaveStaffId;
        this.manageOwnLeave = true;
      }
    },

    showEditLeavePanel() {
      this.showingAddEditLeavePanel = true;
    },
    async handleDeleteLeave(result) {
      if (!result) {
        this.resetConfirmAlert();
        return;
      }

      this.confirmAlert.showing = false;
      this.isBusy = true;

      try {
        await this.performLeaveDeletion();
        this.showNotification("Leave Deleted", "Successfully deleted leave");
      } catch (err) {
        console.error(err);
        this.showNotification(
          "Error",
          "There was a problem deleting the Leave",
          "danger",
        );
      } finally {
        this.pane = false;
        this.isBusy = false;
        this.resetConfirmAlert();
      }
    },

    async performLeaveDeletion() {
      const { staff_id, leaves } = this.confirmAlert.data;
      await this.HolidayService.deleteLeave(staff_id, leaves.leave_id);
      this.removeLeaveFromList(leaves.leave_id);
      this.loadLeaves();
    },

    removeLeaveFromList(leaveId) {
      const idx = this.$_.findIndex(
        this.leaves,
        (leave) => leave.leaves.leave_id === leaveId,
      );
      this.$delete(this.leaves, idx);
    },

    showNotification(title, message, type = "success") {
      this.$breadstick.notify(
        ({ h, onClose }) =>
          h(Notification, { props: { type, title, close: onClose } }, message),
        { position: "top-right" },
      );
    },

    resetConfirmAlert() {
      this.confirmAlert = {
        resultFunction: "",
        message: "",
        showing: false,
        data: "",
      };
    },

    deleteLeave(staff) {
      if (String(staff.leaves.leave_id).startsWith("pending")) {
        this.removeLeaveFromList(staff.leaves.leave_id);
        return;
      }

      this.confirmAlert = {
        resultFunction: this.handleDeleteLeave,
        message: `Are you sure you wish to delete '${this.fullName(
          staff,
        )}'s leave '${staff.leaves.leave_type} This cannot be undone.`,
        showing: true,
        data: staff,
      };
    },
  },
};
</script>
<style>
.fc-event .fc-event-main {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
}
</style>
